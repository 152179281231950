<template>
  <div class="nologin">
    <el-dialog
      :visible.sync="dialogVisible"
      width="1000px"
      append-to-body
      :close-on-click-modal="false"
    >
      <div style="display: flex; height: 600px">
        <div class="left3">
          <div>
            <img src="@/assets/logo.png" alt="" />
            <span>地信宝</span>
          </div>
          <img src="@/assets/loginL.png" alt="" />
        </div>

        <div class="right">
          <!-- 登录注册切换 -->
          <div class="navList" v-if="isPhone">
            <span
              v-for="(item, index) in navList"
              :key="index"
              :class="currentNavIndex == index ? 'navAc' : ''"
              @click="changeNav(index)"
              >{{ item }}</span
            >
          </div>
          <!-- 手机登录 -->
          <div class="phoneLogin" v-if="isPhone && currentNavIndex == 0">
            <div class="phone">
              <div>
                <img src="@/assets/shouji.png" alt="" />
              </div>
              <el-input
                v-model="phoneVal"
                style="border: none"
                placeholder="请输入手机号"
              ></el-input>
            </div>
            <div class="codeNumber">
              <div>
                <div>
                  <img src="@/assets/yanzhengma.png" alt="" />
                </div>
                <el-input
                  v-model="codeVal"
                  style="border: none"
                  placeholder="短信验证码"
                ></el-input>
              </div>
              <div @click="getNum" v-if="isTime">发送验证码</div>
              <div v-else>{{ time }}</div>
            </div>
            <div
              class="loginBtm"
              :class="codeVal ? 'loginBtmAc' : ''"
              @click="Mobilelogin"
            >
              登录
            </div>
            <div class="SMDLAZC">
              <span @click="changeErCode">APP扫码登录</span>
              <span @click="changeMiniErCode">微信扫码注册</span>
            </div>
          </div>
          <!-- 手机注册 -->
          <div class="phoneLogin" v-if="isPhone && currentNavIndex == 1">
            <div class="phone">
              <div>
                <img src="@/assets/shouji.png" alt="" />
              </div>
              <el-input
                v-model="zcPhoneVal"
                style="border: none"
                placeholder="请输入手机号"
              ></el-input>
            </div>
            <div class="codeNumber">
              <div>
                <div>
                  <img src="@/assets/yanzhengma.png" alt="" />
                </div>
                <el-input
                  v-model="zcCodeVal"
                  style="border: none"
                  placeholder="短信验证码"
                ></el-input>
              </div>
              <div @click="getZcNum" v-if="isTime">发送验证码</div>
              <div v-else>{{ time }}</div>
            </div>
            <div
              class="loginBtm"
              :class="zcCodeVal ? 'loginBtmAc' : ''"
              @click="finish"
            >
              注册
            </div>
            <div class="SMDLAZC">
              <span @click="changeErCode">APP扫码登录</span>
              <span @click="changeMiniErCode">微信扫码注册</span>
            </div>
          </div>
          <!-- 扫码登录 -->
          <div v-if="!isPhone">
            <!-- <div class="erweimaQh">
              <img src="../assets/erweimaqiehuan.png" alt="" />
              <div @click="changeErCode">
                <span>{{ loginText }}</span>
              </div>
            </div> -->
            <p
              style="
                font-size: 22px;
                color: #101010;
                margin-top: 108px;
                font-weight: bold;
              "
            >
              {{
                loginText != "微信极速注册" ? "扫码快速登陆" : "微信快速注册"
              }}
            </p>
            <p
              style="font-size: 16px; color: #666666; margin-top: 30px"
              v-if="loginText == 'APP快速登录'"
            >
              使用地信宝APP扫码登录
            </p>
            <p
              style="font-size: 16px; color: #666666; margin-top: 30px"
              v-if="loginText == '微信极速注册'"
            >
              请用微信“扫一扫”扫描下方二维码进入快捷注册
            </p>

            <div
              style="
                position: relative;
                width: 200px;
                margin: 59px auto 30px;
                border: 2px solid #e8e8e8;
              "
            >
              <img
                :class="{ active3: shixiao == true }"
                :src="loginObj.qrCodeUrl"
                width="200"
                height="200"
                alt=""
              />
              <i
                @click="getLoginImg2"
                v-if="shixiao && loginText == '微信极速注册'"
                class="el-icon-refresh"
                style="
                  position: absolute;
                  font-size: 50px;
                  left: 80px;
                  top: 95px;
                  background: #fff;
                "
              ></i>
              <p
                @click="getLoginImg2"
                v-if="shixiao && loginText == '微信极速注册'"
                class="hover1"
              >
                点击刷新
              </p>
            </div>
            <!-- <div
              v-if="loginText == 'APP快速登录'"
              class="hover1"
              style="color: #999999; margin-top: 30px; cursor: pointer"
              @click="isPhone = true"
            >
              手机号登录
            </div> -->
            <div style="" class="SMBtm">
              <div @click="SJDL">
                <img src="@/assets/shoujiL.png" alt="" />
                <span>手机号登录</span>
              </div>
              <span @click="goZC">注册</span>
            </div>
          </div>
          <!-- 手机号登录 -->
          <!-- <div v-else>
            <p style="font-size: 20px; color: #333333; margin-top: 80px">
              验证码登陆
            </p>
            <el-input
              v-model="phoneVal"
              style="width: 60%; margin-top: 50px"
              placeholder="请输入手机号"
              class="phone"
            ></el-input>
            <el-input
              v-model="codeVal"
              style="width: 60%; margin-top: 20px; position: relative"
              placeholder="短信验证码"
              class="yanzhengma"
            ></el-input>
            <span
              v-if="isTime"
              class="hover1"
              style="
                color: #468bf2;
                font-size: 14px;
                position: absolute;
                right: 4%;
                top: 227px;
                cursor: pointer;
              "
              @click="getNum"
              >获取验证码</span
            >
            <span
              v-else
              class="hover1"
              style="
                color: #468bf2;
                font-size: 14px;
                position: absolute;
                right: 9%;
                top: 227px;
              "
              >{{ time }}</span
            >
            <div style="margin-left: 250px">
              <el-checkbox
                v-model="checked"
                style="margin: 20px 0 0 -240px"
              ></el-checkbox>
              <span
                >已经阅读并同意
                <span
                  class="hover1"
                  style="color: #468bf2; cursor: pointer"
                  @click="goUserAgreement(1)"
                  >《用户服务协议》</span
                >和
                <span
                  class="hover1"
                  style="color: #468bf2; cursor: pointer"
                  @click="goUserAgreement(2)"
                  >《隐私政策》</span
                ></span
              >
            </div>

            <div
              class="hover1"
              style="
                width: 60%;
                background: #468bf2;
                color: #fff;
                height: 40px;
                margin: 40px auto 0;
                line-height: 40px;
                cursor: pointer;
              "
              @click="Mobilelogin"
            >
              登录
            </div>
            <div
              class="hover1"
              style="color: #999999; margin-top: 30px; cursor: pointer"
              @click="isPhone = false"
            >
              扫码登录
            </div>
          </div> -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <!-- 职场人和学生注册完善信息1显示 -->
    <el-dialog
      :visible.sync="WsShowOne"
      width="600px"
      append-to-body
      :close-on-click-modal="false"
      custom-class="WsOne"
    >
      <div style="height: 600px">
        <div class="Wsone-top">
          <img src="@/assets/logo.png" alt="" />
          <span>地信宝</span>
        </div>
        <div class="Wsone-upload">
          <span>可上传附件简历。一键完成在线简历填写</span>
          <div>
            <span>1、支持PDF文件，也支持DOC、DOCX、JPG、PNG 格式。</span>
            <span>2、文件大小不超过00M</span>
          </div>
        </div>
        <div class="Wsone-userinfo">
          <!-- 基本信息头部显示 -->
          <div>
            <span>基本信息</span>
            <span>请填写真实信息，招聘者可以通过这些条件搜索到你！</span>
          </div>
          <!-- 姓名头像 -->
          <div>
            <div>
              <span>姓名</span>
              <div>
                <input
                  type="text"
                  class="inputT"
                  v-model="nameVal"
                  placeholder="请输入您的姓名"
                />
              </div>
            </div>
            <div>
              <el-upload
                class="avatar-uploader"
                action="https://www.dixinbao.cn/api/common/uploads"
                :show-file-list="false"
                :headers="headers"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload2"
                :limit="1"
              >
                <img
                  v-if="imageUrls"
                  :src="imageUrls"
                  class="avatar"
                  style="width: 100px; height: 100px; border-radius: 50%"
                />
                <!-- <div v-else class="noImageUrl">
                  <span>上传头像</span>
                </div> -->
              </el-upload>
            </div>
          </div>
          <!-- 性别显示 -->
          <div class="Wsone-item">
            <span>性别</span>
            <div>
              <span
                v-for="(item, index) in sexList"
                :class="sexCurrentIndex == index ? 'Ac' : ''"
                @click="sexChange(index)"
                :key="index"
                >{{ item }}</span
              >
            </div>
          </div>
          <!-- 求职身份 -->
          <div class="Wsone-item">
            <span>求职身份</span>
            <div>
              <span
                v-for="(item, index) in identityList"
                :class="identityCurrentIndex == index ? 'Ac' : ''"
                @click="identityChange(index)"
                :key="index"
                >{{ item }}</span
              >
            </div>
          </div>
          <!-- 出生日期 -->
          <div class="Wsone-birthday">
            <span>出生年月</span>
            <div>
              <el-date-picker
                v-model="birthdayTime"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
              <img src="@/assets/NologinImg/down.png" alt="" />
            </div>
          </div>
          <!-- 工作时间 -->
          <div class="Wsone-birthday" v-if="identityCurrentIndex == 0">
            <span>工作时间</span>
            <div>
              <el-date-picker
                v-model="workTime"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
              <img src="@/assets/NologinImg/down.png" alt="" />
            </div>
          </div>
          <!-- 当前求职状态 -->
          <div class="Wsone-state" v-if="identityCurrentIndex == 0">
            <span>当前求职状态</span>
            <div>
              <span
                v-for="item in jobstatusList"
                :class="jobstatusCurrentId == item.id ? 'Ac' : ''"
                @click="jobstatusChange(item.id)"
                :key="item.id"
              >
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="Wsone-nextSubmit">
          <span @click="subWsone">下一步</span>
        </div>
      </div>
    </el-dialog>
    <!-- 选择职场人注册完善信息2显示 -->
    <el-dialog
      :visible.sync="WsShowTwo"
      width="600px"
      append-to-body
      :close-on-click-modal="false"
      custom-class="WsTwo"
    >
      <div style="height: 600px">
        <div class="Wstwo-top">
          <img src="@/assets/logo.png" alt="" />
          <span>地信宝</span>
        </div>
        <div class="Wstwo-title">
          <span>工作经历</span>
          <span>请填写您最近的一份工作经历</span>
        </div>
        <div class="Wstwo-companyInfo">
          <div class="company-name">
            <span>公司名称</span>
            <input
              type="text"
              placeholder="请输入公司名称"
              v-model="comPanyName"
            />
          </div>
          <div class="company-time">
            <span>在职时间</span>
            <div>
              <div>
                <el-date-picker
                  v-model="EntryTime"
                  type="date"
                  placeholder="选择入职时间"
                >
                </el-date-picker>
                <img src="@/assets/NologinImg/down.png" alt="" />
              </div>
              <div>
                <el-date-picker
                  v-model="TerminationTime"
                  type="date"
                  placeholder="选择离职时间"
                >
                </el-date-picker>
                <img src="@/assets/NologinImg/down.png" alt="" />
              </div>
            </div>
          </div>
          <div class="company-position">
            <span>所任职位</span>
            <input
              type="text"
              placeholder="请输入职位名称"
              v-model="PositionName"
            />
          </div>
        </div>
        <div class="Wstwo-submit">
          <span @click="subWstwoUp">上一步</span>
          <span @click="subWstwoSubmit">下一步</span>
        </div>
      </div>
    </el-dialog>
    <!-- 选择职场人注册完善信息3显示 -->
    <el-dialog
      :visible.sync="WsShowThree"
      width="600px"
      append-to-body
      :close-on-click-modal="false"
      custom-class="WsThree"
    >
      <div style="height: 600px">
        <div class="WsThree-top">
          <img src="@/assets/logo.png" alt="" />
          <span>地信宝</span>
        </div>
        <div class="WsThree-title">
          <span>教育经历</span>
          <span>请填写您的学历信息</span>
        </div>
        <div class="WsThree-companyInfo">
          <div class="company-name">
            <span>学校名称</span>
            <input
              type="text"
              placeholder="请输入学校名称"
              v-model="SchoolName"
            />
          </div>
          <div class="WsThree-education">
            <span>学历</span>
            <div>
              <div>
                <el-select
                  v-model="diplomaName"
                  placeholder="请选择您的学历"
                  @change="diplomaChange"
                >
                  <el-option
                    v-for="item in diplomaList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <img src="@/assets/NologinImg/down.png" alt="" />
              </div>
              <div>
                <el-select
                  v-model="Diplomacate"
                  placeholder="请选择学历性质"
                  @change="diplomaCateChange"
                >
                  <el-option
                    v-for="item in DiplomacateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <img src="@/assets/NologinImg/down.png" alt="" />
              </div>
            </div>
          </div>
          <div class="company-position">
            <span>所学专业</span>
            <input type="text" placeholder="请输入专业名称" v-model="Major" />
          </div>
          <div class="company-time">
            <span>在校时间</span>
            <div>
              <div>
                <el-date-picker
                  v-model="TimeOfAdmission"
                  type="date"
                  placeholder="入学时间"
                >
                </el-date-picker>
                <img src="@/assets/NologinImg/down.png" alt="" />
              </div>
              <div>
                <el-date-picker
                  v-model="GraduationTime"
                  type="date"
                  placeholder="毕业时间"
                >
                </el-date-picker>
                <img src="@/assets/NologinImg/down.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="Wsthree-submit">
          <span @click="subWsThreeUp">上一步</span>
          <span @click="subWsThreeSubmit">下一步</span>
        </div>
      </div>
    </el-dialog>
    <!-- 选择职场人注册完善信息4显示 -->
    <el-dialog
      :visible.sync="WsShowFour"
      width="600px"
      append-to-body
      :close-on-click-modal="false"
      custom-class="WsFour"
    >
      <div style="height: 600px">
        <div class="WsFour-top">
          <img src="@/assets/logo.png" alt="" />
          <span>地信宝</span>
        </div>
        <div class="WsFour-title">
          <span>求职期望</span>
          <span>我们会根据您的求职期望为您推荐合适的职位</span>
        </div>
        <div class="WsFour-positionInfo">
          <div class="positionInfo-expect">
            <span>期望职位</span>
            <div>
              <el-cascader
                v-model="CascaderValue"
                :placeholder="CascaderValue ? CascaderValue : '期望职位'"
                :options="positionList"
                :filterable="false"
                ref="cascaderAddr"
                :show-all-levels="false"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange()"
              ></el-cascader>
            </div>
          </div>
          <div class="positionInfo-salary">
            <span>期望薪资范围</span>
            <div>
              <div>
                <el-select
                  v-model="SalaryLeftText"
                  placeholder="请选择期望薪资范围"
                  @change="SalaryLeftChange"
                >
                  <el-option
                    v-for="item in SalaryLeft"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <img src="@/assets/NologinImg/down.png" alt="">
              </div>
              <div>
                <el-select
                  v-model="SalaryRightText"
                  @change="SalaryRightChange"
                  placeholder="请选择期望薪资范围"
                >
                  <el-option
                    v-for="item in SalaryRight"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <img src="@/assets/NologinImg/down.png" alt=""></img>
              </div>
            </div>
          </div>
          <div class="positionInfo-city">
            <span>期望工作地点</span>
            <div>
               <el-cascader
                    :value="cityValue"
                    :placeholder="cityValue ? cityValue : '工作城市'"
                    :options="cityList"
                    ref="city"
                    :filterable="false"
                    :props="{ expandTrigger: 'hover' }"
                    @change="handleCityChange()"
                  ></el-cascader>
            </div>
          </div>
        </div>
        <div class="WsFour-submit">
           <span @click="subWsFourUp">上一步</span>
          <span @click="subWsFourSubmit">下一步</span>
        </div>
      </div>
    </el-dialog>
    <!-- 选择学生注册完善信息2显示 -->
    <el-dialog
      :visible.sync="StuWsTwoShow"
      width="600px"
      append-to-body
      :close-on-click-modal="false"
      custom-class="WsTwo"
    >
      <div style="height: 600px">
        <div class="Wstwo-top">
          <img src="@/assets/logo.png" alt="" />
          <span>地信宝</span>
        </div>
        <div class="Wstwo-titles">
          <div>
              <span>实习经历</span>
              <span>请填写您最近的一份实习经历</span>
          </div>
          <div @click="Tg"> 
              <img src="@/assets/NologinImg/tiaoguo.png" alt="">
              <span>跳过</span>
          </div>
        </div>
        <div class="Wstwo-companyInfo">
          <div class="company-name">
            <span>公司名称</span>
            <input
              type="text"
              placeholder="请输入公司名称"
              v-model="StucomPanyName"
            />
          </div>
          <div class="company-time">
            <span>在职时间</span>
            <div>
              <div>
                <el-date-picker
                  v-model="StuEntryTime"
                  type="date"
                  placeholder="选择入职时间"
                >
                </el-date-picker>
                <img src="@/assets/NologinImg/down.png" alt="" />
              </div>
              <div>
                <el-date-picker
                  v-model="StuTerminationTime"
                  type="date"
                  placeholder="选择离职时间"
                >
                </el-date-picker>
                <img src="@/assets/NologinImg/down.png" alt="" />
              </div>
            </div>
          </div>
          <div class="company-position">
            <span>所任职位</span>
            <input
              type="text"
              placeholder="请输入职位名称"
              v-model="StuPositionName"
            />
          </div>
        </div>
        <div class="Wstwo-submit">
          <span @click="StuWsTwoUp">上一步</span>
          <span @click="StuWsTwoSub">下一步</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TIM from "tim-js-sdk";
// import TIM from 'tim-wx-sdk'; // 微信小程序环境请取消本行注释，并注释掉 import TIM from 'tim-js-sdk';
import TIMUploadPlugin from "tim-upload-plugin"; // 使用前请将 IM SDK 升级到v2.9.2或更高版本
export default {
  data() {
    return {
      navList: ["登录", "注册"],
      currentNavIndex: 0,
      phoneVal: "",
      zcPhoneVal: "",
      zcCodeVal: "",
      codeVal: "",
      dialogVisible: false,
      loginObj: {},
      shixiao: false,
      isPhone: true,
      time: 60,
      isTime: true,
      checked: "1",
      token: "",
      UserID: "",
      UserSig: "",
      loginText: "微信极速注册",
      currentNavIndex: 0,
      //完善职场人和学生信息弹窗1参数
      WsShowOne: false,
      headers:'',
      nameVal: "",
      imageUrl: "",
      imageUrls:'https://www.dixinbao.cn/assets/img/tou.png',
      sexList: ["男", "女"],
      sexCurrentIndex: 0,
      identityList: ["职场人", "学生"],
      identityCurrentIndex: 0,
      birthdayTime: "",
      workTime: "",
      jobstatusList: [],
      jobstatusCurrentId: "",
      WorkList: [],
      JobPurposeList: [],
      EducationList:[],
      //完善职场人信息弹窗2参数
      WsShowTwo: false,
      comPanyName: "", //公司名称
      EntryTime: "", //入职时间
      TerminationTime: "", //离职时间
      PositionName: "", //职位名称
      //完善职场人信息弹窗3参数
      SchoolName: "",
      WsShowThree: false,
      diplomaList: [], //学历列表
      diplomaName: "", //学历
      DiplomacateList: [], //学历性质
      diplomaCateId: '',//选中的学历性质id
       DiplomaId: "", //选中的学历id
      Diplomacate: "", //学历性质
      Major: "", //专业
      TimeOfAdmission: "", //入学时间
      GraduationTime:'', //毕业时间
      //完善职场人信息弹窗4参数
      WsShowFour: false,
      CascaderValue: "", //期望职位
      CascaderId: "", //职位id
      positionList: [], //求职意向求职列表
      SalaryLeft: [], //求职意向左侧薪资
      SalaryLeftText: "", //左侧选中的薪资
      SalaryRight: [], //求职意向右侧薪资
      SalaryRightText: "", //右侧选中的薪资
      province: '',//选择省份
      city:'',//选择的城市
      cityValue: "", //求职期望公司城市
      cityList: [], //求职意向省市区三级
       //完善学生信息弹窗2显示参数
      StuWsTwoShow: false,
       StucomPanyName: "", //公司名称
      StuEntryTime: "", //入职时间
      StuTerminationTime: "", //离职时间
      StuPositionName: "", //职位名称
    }
  },
  props: {
    isShow: Boolean,
  },
  created() {
    
     this.headers = {
      type: 1,
      token: sessionStorage.getItem("token"),
     };
    
    this.getCodeLogin();
    this.getJobstatusList(); //获取求职状态
    this.getDiploma(); //获取学历类型选择列表
    this.getDiplomacate(); //获取学历性质选择列表
    this.getPositionCate(); //获取求职意向求职列表
    this.getSalaryLeft(); //获取求职意向左侧薪资
    // this.getUserRegionList(); //获取求职意向省市区三级
    this.getWorkList();//获取工作经历列表
    this.getJobPurposeList(),//获取求职期望列表
    this.getEducationList()//获取教育经历列表
  },
  beforeMount() {
    
  },
  mounted() {
    // this.$bus.on("noLogin", () => {
    //   this.dialogVisible = true;
    // });
    this.$bus.on("noWsone", () => {
      console.log('this.$bus.on')
      this.WsShowOne = true
    });
    this.$bus.on("noWsTwo", () => {
      this.WsShowTwo = true
    });
      this.$bus.on("noWsThree", () => {
      this.WsShowThree = true
     });
      this.$bus.on("noWsFour", () => {
      this.WsShowFour = true
     });
  },
  methods: {
    
    //登录注册切换
    changeNav(i) {
      this.currentNavIndex = i;
      this.isTime = true
    },

    //获取登录二维码
    getCodeLogin() {
      this.$http({
        url: "/communal/qrCode",
        method: "GET",
      }).then((res) => {
        this.loginObj = res.data;
      });
      if (!this.isPhone) {
        setTimeout(async () => {
          if (!this.isPhone) {
            this.shixiao = true;
          }
        }, 60000);
        setInterval(async () => {
          if (this.dialogVisible && !this.shixiao && !this.isPhone) {
            this.$http({
              url: "/communal/getPcloginStatus",
              method: "POST",
              data: {
                sign: this.loginObj.sign,
              },
            }).then((res) => {
              if (res.code == 1) {
                this.isLogin = true;
                this.dialogVisible = false;
                console.log(res, "登陆成功");
                sessionStorage.setItem("token", res.data.token);
                this.token = res.data.token;
                this.$http({
                  url: "/communal/accountImport",
                  method: "GET"
                  
                }).then((ress) => {
                  if (ress.code == 1) {
                    this.UserID = ress.data.UserID;
                    localStorage.setItem("UserID", ress.data.UserID);
                    console.log(ress.data.UserID, "ress.data.UserID");
                  } else {
                    this.$message.error(
                      "没有查询到用户信息，请先到APP注册后在登录"
                    );
                  }
                });
                this.$http({
                  url: "/communal/getUserSig",
                  method: "GET",
                }).then((resss) => {
                  if (resss.code == 1) {
                    this.UserSig = resss.data;
                    localStorage.setItem("UserSig", resss.data);
                    console.log(resss.data, "UserSig");
                  }
                });

                this.$http({
                  url: "/business/user/getUserinfo",
                  method: "GET",
                }).then((ressss) => {
                  localStorage.setItem("userInfo", JSON.stringify(ressss.data));
                  this.$bus.emit("Login");
                  this.$router.go(0);
                });
              }
            });

            if (res.data.code == 1) {
              this.$http({
                url: "/communal/getUserSig",
                method: "GET",
              });
              if (result2.status == 200) {
                localStorage.setItem("UserSig", result2.data.data);
              }

              let res2 = await console.log(res2, "user");

              return;
            }
            console.log(res, "轮询");
          }
        }, 2000);
      }
    },
    //发送验证码
    getNum() {
      console.log("111");
      this.$http({
        url: "/sms/send",
        data: {
          mobile: this.phoneVal,
          event: "pcmobilelogin",
        },
        method: "POST",
      }).then((res) => {
        console.log(res, "res");
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.isTime = false;
          if (this.time > 1) {
            setInterval(() => {
              if (this.time > 1) {
                this.time--;
              } else {
                this.isTime = true;
                this.time = 60;
              }
            }, 1000);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 注册获取验证码
    getZcNum() {
      let phonereg =
        /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/;
      let isTel = phonereg.test(this.zcPhoneVal);
      if (isTel) {
        this.$http({
          url: "/sms/send",
          data: {
            mobile: this.zcPhoneVal,
            event: "register",
          },
          method: "POST",
        }).then((res) => {
          console.log(res, "res");
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.isTime = false;
            if (this.time > 1) {
              setInterval(() => {
                if (this.time > 1) {
                  this.time--;
                } else {
                  this.isTime = true;
                  this.time = 60;
                }
              }, 1000);
            }
             this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message.error("您输入的手机号不正确,请检查后重试");
      }
    },
    // 登录
    Mobilelogin() {
      let phonereg =
        /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/;
      let isTel = phonereg.test(this.phoneVal);
      if (isTel) {
        if (this.codeVal) {
          if (this.checked) {
            this.$http({
              url: "/login/pcmobilelogin",
              method: "POST",
              data: {
                mobile: this.phoneVal,
                captcha: this.codeVal,
              },
            }).then((res) => {
              if (res.code == 1) {
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(res.data.userinfo)
                );
                sessionStorage.setItem("token", res.data.userinfo.token);
                // 导入账号
                this.$http({
                  url: "/communal/accountImport",
                }).then((ress) => {
                  if (ress.code == 1) {
                    localStorage.setItem("UserID", ress.data.UserID);
                    this.$http({
                      url: "/communal/getUserSig",
                    }).then((resss) => {
                      if (resss.code == 1) {
                        localStorage.setItem("UserSig", resss.data);
                        this.$message.success("登录成功");
                        this.dialogVisible = false;
                        this.$bus.emit("Login");
                        console.log(this.$parent)
                      }
                    });
                  } else {
                    this.dialogVisible = false;
                    this.$bus.emit("Login");
                  }
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message.error("请勾选用户服务协议和隐私政策");
          }
        } else {
          console.log(this.codeVal);
          this.$message.error("请输入验证码");
        }
      } else {
        this.$message.error("您输入的手机号不正确,请检查后重试");
      }
    },
    // 注册
    finish() {
      let phonereg =
        /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/;
      let isTel = phonereg.test(this.zcPhoneVal);
      if (isTel) {
        if (this.zcCodeVal) {
          if (this.checked) {
            this.$http({
              url: "/login/register",
              method: "POST",
              data: {
                mobile: this.zcPhoneVal,
                captcha: this.zcCodeVal,
              },
            }).then((res) => {
              if (res.code == 1) {
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(res.data.userinfo)
                );
                sessionStorage.setItem("token", res.data.userinfo.token);
                this.zcPhoneVal = "";
                this.zcCodeVal = "";
                // this.$message.success("登录成功");
                this.WsShowOne = true;
                // 导入账号
                this.$http({
                  url: "/communal/accountImport",
                }).then((ress) => {
                  if (ress.code == 1) {
                    localStorage.setItem("UserID", ress.data.UserID);
                    this.$http({
                      url: "/communal/getUserSig",
                    }).then((resss) => {
                      if (resss.code == 1) {
                        localStorage.setItem("UserSig", resss.data);
                        this.$message.success("登录成功");
                        this.dialogVisible = false;
                        this.$bus.emit("Login");
                      }
                    });
                  } else {
                    this.dialogVisible = false;
                    this.$bus.emit("Login");
                  }
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message.error("请勾选用户服务协议和隐私政策");
          }
        } else {
          console.log(this.codeVal);
          this.$message.error("请输入验证码");
        }
      } else {
        this.$message.error("您输入的手机号不正确,请检查后重试");
      }
    },
    //刷新二维码
    getLoginImg2() {
      this.$http({
        url: "/communal/qrCode",
        method: "GET",
      }).then((res) => {
        if (res.code == 1) {
          this.loginObj = res.data;
          console.log(this.loginObj, "login");
          this.shixiao = false;
          setTimeout(() => {
            this.shixiao = true;
          }, 60000);
        }
      });
    },
    //切换App二维码
    changeErCode() {
      this.$http({
        url: "/communal/qrCode",
        method: "GET",
      }).then((res) => {
        this.isPhone = false;
        this.loginText = "APP快速登录";
        this.loginObj = res.data;
        setInterval(async () => {
          let res = await this.$http({
            url: "/communal/getPcloginStatus",
            method: "POST",
            data: {
              sign: this.loginObj.sign,
            },
          });
          if (res.code == 1) {
            this.isLogin = true;
            this.dialogVisible = false;
            console.log(res, "登陆成功");
            // console.log(TIM.EVENT,'EVENT');
            sessionStorage.setItem("token", res.data.token);

            let result1 = await this.$http({
              url: "/communal/accountImport",
              method: "GET",
            });
            console.log(result1,'result1')
            if (result1.code==1) {
              localStorage.setItem("UserID", result1.data.UserID);
            }
            // console.log(result1, "res1");

            let result2 = await this.$http({
              url: "/communal/getUserSig",
              method: "GET",
            });
             console.log(result2,'result2')
            if (result2.code ==1 ) {
              localStorage.setItem("UserSig", result2.data);
            }
            let promise = this.tim.login({
              userID: localStorage.getItem("UserID"),
              userSig: localStorage.getItem("UserSig"),
            });
            promise
              .then(function (imResponse) {
                console.log(imResponse.data); // 登录成功
                console.log("登录成功", 11111111111);

                if (imResponse.data.repeatLogin === true) {
                  // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                  console.log(imResponse.data.errorInfo);
                }
              })
              .catch(function (imError) {
                console.warn("login error:", imError); // 登录失败的相关信息
              });

            let res2 = await this.$http({
              url: "/business/user/getUserinfo",
              method: "GET",
            });
            console.log(res2, "user");
            if (res2.data) {
               this.userName = res2.data.name;
               this.userImg = res2.data.image;
               // console.log(res.data.data.name,'name');
               localStorage.setItem("userInfo", JSON.stringify(res2.data));
            } else {
              localStorage.setItem("userInfo", JSON.stringify(res2.data));
            }
            this.$router.go(0);
            return;
          }
        }, 2000);
      });
    },
    SJDL() {
      this.isPhone = true;
      this.currentNavIndex = 0;
    },
    goZC() {
      this.isPhone = true;
      this.currentNavIndex = 1;
    },
    //切换微信小程序二维码
    changeMiniErCode() {
      this.$http({
        url: "/login/getnimicode",
        method: "GET",
      }).then((res) => {
        console.log(res, "getnimicode");
        this.isPhone = false;
        this.loginText = "微信极速注册";
        this.loginObj.qrCodeUrl = res.data;
      });
    },
    //跳转用户服务协议
    goUserAgreement(type) {
      this.dialogVisible = false;
      this.$router.push("/useragreement?type=" + type);
    },
    // 注册信息完善部分
    // 头像上传
    handleAvatarSuccess(res, file, fileList) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res, file, fileList, "res1111");
       this.imageUrls = res.data.fullurl
       this.imageUrl = res.data.url
    },
    beforeAvatarUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
    //获取求职状态
    getJobstatusList() {
      this.$http({
        url: "/communal/getJobstatusList",
      }).then((res) => {
        this.jobstatusList = res.data;
        this.jobstatusCurrentId = res.data[0].id;
      });
    },
    //获取学历选择列表
    getDiploma() {
      this.$http({
        url: "/communal/getDiploma",
      }).then((res) => {
        res.data.forEach((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          this.diplomaList.push(obj);
        });
        console.log(this.diplomaList, "this.diplomaList");
      });
    },
    //获取学历性质
    getDiplomacate() {
      this.$http({
        url: "/communal/getDiplomacate",
      }).then((res) => {
        res.data.forEach((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          this.DiplomacateList.push(obj);
        });
        console.log(this.DiplomacateList, "this.diplomaList");
      });
    },
    // 获取期望职位列表
    getPositionCate() {
      this.$http({
        url: "/communal/getUserThreePosition",
        method: "GET",
      }).then((res) => {
        console.log(res.data, "res");
        this.positionList = res.data;
        console.log(this.options, "this.options");
      });
    },
    //获取求职期望职位
    handleChange() {
      const checkedNodes = this.$refs["cascaderAddr"].getCheckedNodes(); // 获取当前点击的节点
      console.log(checkedNodes);
      this.CascaderVal = checkedNodes[0].data.label;
      this.CascaderId = checkedNodes[0].pathNodes[2].value;
    },
    //获取左侧薪资
    getSalaryLeft() {
      this.$http({
        url: "/communal/getSalary",
      }).then((res) => {
        if (res.code == 1) {
          res.data.forEach((item) => {
            let obj;
            obj = {
              value: item.id,
              label: item.name,
            };
            this.SalaryLeft.push(obj);
          });
          this.getSalaryRight(this.SalaryLeft[0].value);
          // this.SalaryLeftText = this.SalaryLeft[0].label;
        }
      });
    },
    //获取右侧薪资
    getSalaryRight(id) {
      this.$http({
        url: "/communal/getFsalary",
        params: {
          id,
        },
      }).then((res) => {
        if (res.code == 1) {
          res.data.forEach((item) => {
            let obj;
            obj = {
              value: item.id,
              label: item.name,
            };
            this.SalaryRight.push(obj);
            // this.SalaryRightText = this.SalaryRight[0].label;
          });
        }
      });
    },
      //获取城市三级列表
    // getUserRegionList() {
    //   this.$http({
    //     url: "/communal/getUserRegionList",
    //   }).then((res) => {
    //     this.cityList = res.data;
    //   });
    // },
    // 性别切换
    sexChange(index) {
      this.sexCurrentIndex = index;
    },
    //身份切换
    identityChange(index) {
      this.identityCurrentIndex = index;
    },
    // 求职状态切换
    jobstatusChange(id) {
      this.jobstatusCurrentId = id;
    },
    //改变学历选中
    diplomaChange(e) {
      this.DiplomaId = e;
    },
    //改变学历性质选中
    diplomaCateChange(e) {
      this.diplomaCateId = e;
    },
     //选择期望职位左侧薪资
    SalaryLeftChange(e) {
      console.log(e);
      this.SalaryRight = [];
      this.getSalaryRight(e);
    },
    //选中的期望职位右侧薪资
    SalaryRightChange(e) {
      console.log(e, "111");
    },
         //获取城市
    handleCityChange() {
      const checkedNodes = this.$refs["city"].getCheckedNodes(); // 获取当前点击的节点
      console.log(checkedNodes);
      this.province = checkedNodes[0].parent.parent.label;
      this.city = checkedNodes[0].parent.label;
    },
    //弹窗完善1点击下一步
    formateTime(time) {
     if(time){
       let y;
      let m;
      let d;
      y = new Date(time).getFullYear()
      m = new Date(time).getMonth()+1
      d = new Date(time).getDate()
      return y + '-' + m + '-' + d
     }
    },
    subWsone() {
      if (this.identityCurrentIndex ==0) {
        let obj = {
        birthday: this.formateTime(this.birthdayTime)?this.formateTime(this.birthdayTime):'',
        identity: this.identityCurrentIndex==0?2:1,
        image: this.imageUrl,
        jobstatus: this.jobstatusCurrentId,
        name: this.nameVal,
        sex: this.sexCurrentIndex==0?1:2,
        worktime:this.formateTime(this.workTime)?this.formateTime(this.workTime):'',
        }
       console.log(obj,'obj')
       let flag = Object.values(obj).every(item => {
           return item !=''
        })
        console.log(flag, 'Object.values(obj)')
        if (flag) {
           this.$http({
            url: "/users/user/profile",
            method: "POST",
            data:{
              ...obj
            }
          }).then((res) => {
            if (res.code == 1) {
              this.WsShowOne = false
               if (this.WorkList.length <= 0) {
                 this.WsShowTwo = true
              } else if(this.EducationList.length<=0){
                 this.WsShowThree = true
               } else if (this.JobPurposeList.length <= 0) {
                 this.WsShowFour = true
               } else {
                  this.$bus.emit("Login");
              }
            }
          });
        } else {
          this.$message.error('请检查您的输入项是否完整')
        }
      } else {
         let obj = {
              birthday: this.formateTime(this.birthdayTime)?this.formateTime(this.birthdayTime):'',
              identity: this.identityCurrentIndex==0?2:1,
              image: this.imageUrl,
              // jobstatus: this.jobstatusCurrentId,
              name: this.nameVal,
              sex: this.sexCurrentIndex==0?1:2,
              // worktime:this.formateTime(this.workTime)?this.formateTime(this.workTime):'',
        }
       console.log(obj,'obj')
       let flag = Object.values(obj).every(item => {
           return item !=''
        })
        console.log(flag, 'Object.values(obj)')
        if (flag) {
           this.$http({
            url: "/users/user/profile",
            method: "POST",
            data:{
              ...obj
            }
          }).then((res) => {
            if(res.code == 1){
              this.WsShowOne = false
              //判断是否填写经历,如果没有填写则显示
              if (this.WorkList.length <= 0) {
                 this.StuWsTwoShow = true
              } else {
                  this.$bus.emit("Login");
              }
            }
          });
        } else {
          this.$message.error('请检查您的输入项是否完整')
        }
        
      }
    },
     //获取工作经历
    getWorkList() {
      this.$http({
        url: "/users/user/getWorkList",
        method: "POST",
      }).then((res) => {
        this.WorkList = res.data;
      });
    },
    //获取教育经历
    getEducationList() {
       this.$http({
        url: "/users/user/getEducationList",
        method: "POST",
      }).then((res) => {
        this.EducationList = res.data;
      });
    },
    //获取求职期望列表
    getJobPurposeList() {
      this.$http({
        url: "/users/user/getJobPurposeList",
        method: "GET",
      }).then((res) => {
        this.JobPurposeList = res.data.list;
      });
    },
    //职场人完善弹窗2
    // 上一步
    subWstwoUp() {
      this.WsShowTwo = false
      this.WsShowOne = true
    },
    //下一步
    subWstwoSubmit() {
      let obj = {
        endtime:this.formateTime(this.TerminationTime)?this.formateTime(this.TerminationTime):'',
        name:this.comPanyName,
        starttime:this.formateTime(this.EntryTime)?this.formateTime(this.EntryTime):'',
        position_name:this.PositionName
      }
       let flag = Object.values(obj).every(item => {
           return item !=''
       })
      if (flag) {
           this.$http({
            url: "/users/user/setWorks",
            method: "POST",
            data:{
              ...obj,
              position_id: '',
              content: '',
            }
          }).then((res) => {
            if(res.code == 1){
              this.WsShowTwo = false
               if(this.EducationList.length<=0){
                 this.WsShowThree = true
               } else if (this.JobPurposeList.length <= 0) {
                 this.WsShowFour = true
               } else {
                  this.$bus.emit("Login");
              }
            }
          });
        } else {
          this.$message.error('请检查您的输入项是否完整')
      }
    },
    //职场人完善弹窗3
     // 上一步
    subWsThreeUp() {
      this.WsShowThree = false
      this.WsShowTwo = true
    },
     //下一步
    subWsThreeSubmit() {
      let obj = {
        name: this.SchoolName,
        diploma_id: this.DiplomaId,
        diploma_cate_id: this.diplomaCateId,
        major: this.Major,
        starttime:this.formateTime(this.TimeOfAdmission)?this.formateTime(this.TimeOfAdmission).slice(0,4):'',
        endtime:this.formateTime(this.GraduationTime)?this.formateTime(this.GraduationTime).slice(0,4):'',
      }
       let flag = Object.values(obj).every(item => {
           return item !=''
       })
      if (flag) {
           this.$http({
            url: "/users/user/setEducations",
            method: "POST",
            data:{
              ...obj,
              content: '',
            }
          }).then((res) => {
            if(res.code == 1){
               this.WsShowThree = false
              if (this.JobPurposeList.length <= 0) {
                 this.WsShowFour = true
              } else {
                this.WsShowFour = false
                   this.$bus.emit("Login");
              }
            }
          });
        } else {
          this.$message.error('请检查您的输入项是否完整')
      }
    },
    //职场人完善弹窗4
    // 上一步
    subWsFourUp() {
      this.WsShowFour = false
      this.WsShowThree = true
    },
    // 下一步
    subWsFourSubmit() {
      let obj = {
        position_id: this.CascaderId,
        startprice_id:this.SalaryLeftText,
        endprice_id: this.SalaryRightText,
        province: this.province,
        city:this.city
      }
       let flag = Object.values(obj).every(item => {
           return item !=''
       })
      if (flag) {
           this.$http({
            url: "/users/user/setJobPurpose",
            method: "POST",
            data:{
              ...obj,
             id:'',
             nature_id:'',
            }
          }).then((res) => {
            if(res.code == 1){
              this.WsShowFour = false
              this.$bus.emit("Login");
            }
          });
        } else {
          this.$message.error('请检查您的输入项是否完整')
      }
      
    },
    //学生完善弹窗2显示
    //上一步
    StuWsTwoUp() {
      this.StuWsTwoShow = false
      this.WsShowOne = true
    },
    //下一步
    StuWsTwoSub() {
      let obj = {
        endtime:this.formateTime(this.StuTerminationTime)?this.formateTime(this.StuTerminationTime):'',
        name:this.StucomPanyName,
        starttime:this.formateTime(this.StuEntryTime)?this.formateTime(this.StuEntryTime):'',
        position_name:this.StuPositionName
      }
       let flag = Object.values(obj).every(item => {
           return item !=''
       })
      if (flag) {
           this.$http({
            url: "/users/user/setWorks",
            method: "POST",
            data:{
              ...obj,
              position_id: '',
              content: '',
            }
          }).then((res) => {
            if(res.code == 1){
              this.StuWsTwoShow = false
               this.$bus.emit("Login");
            }
          });
        } else {
          this.$message.error('请检查您的输入项是否完整')
      }
    },
    //跳过
    Tg() {
       this.StuWsTwoShow = false
    }
  }
};
</script>

<style lang="less" scoped>

/deep/.el-dialog__header {
  padding: 0 !important;
}
/deep/.el-dialog__footer {
  padding: 0 !important;
}
/deep/.el-dialog__body {
  height: 600px;
  padding: 0 !important;
}
/deep/.el-input__inner {
  border: none !important;
}
/deep/.el-input__icon {
  display: none !important;
}
/deep/.el-input--prefix,
/deep/.el-input__inner {
  padding-left: 10px !important;
}
.inputT {
  outline: none;
  border-width: 0 !important;
  background-color: #fff;
  font-size: 26rpx;
  color: #999999;
  &:focus {
    border: none;
    outline: none;
  }
}
.left3 {
  height: 100%;
  width: 440px;
  height: 600px;
  background: url("@/assets/loginlBgc.png") center center no-repeat;
  background-size: cover;
  text-align: center;
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    padding: 40px 0 0 40px;
    box-sizing: border-box;
    > img:nth-child(1) {
      width: 32px;
      height: 32px;
      margin-right: 17px;
    }
    > span:nth-child(2) {
      font-weight: 700;
      font-size: 30px;
      color: #2e74ff;
    }
  }
  > img:nth-child(2) {
    width: 360px;
    height: 239px;
    margin-top: 108px;
  }
}
//完善职场人和学生弹窗1
.WsOne {
  width: 600px;
  height: 700px;
  padding: 20px 30px 50px;
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .Wsone-top {
    width: 520px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #2e74ff;
    > img:nth-child(1) {
      width: 40px;
      height: 40px;
      margin-right: 17px;
    }
  }
  .Wsone-upload {
    width: 520px;
    height: 82px;
    padding: 15px 20px;
    margin: 30px auto 50px;
    border: 2px solid #e8e8e8;
    border-radius: 2px;
    box-sizing: border-box;
    > span:nth-child(1) {
      font-size: 16px;
      font-weight: 700;
      color: #101010;
    }
    > div:nth-child(2) {
      margin-top: 10px;
      font-size: 12px;
      color: #999999;
    }
  }
  .Wsone-userinfo {
    width: 520px;
    margin: 50px auto 0;
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #aaaaaa;
      > span:nth-child(1) {
        font-size: 22px;
        color: #101010;
        margin-bottom: 10px;
      }
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 28px 0 11px 0;
      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 80px;
        > span:nth-child(1) {
          font-size: 14px;
          color: #666666;
        }
        > div:nth-child(2) {
          display: flex;
          align-items: center;
          width: 230px;
          height: 42px;
          padding: 0 15px;
          border: 2px solid #e8e8e8;
          border-radius: 2px;
          box-sizing: border-box;
        }
      }
      > div:nth-child(2) {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        color: #999999;
        font-size: 14px;
        background: #e8e8e8;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
    .Wsone-item {
      margin-bottom: 25px;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        width: 520px;
        margin-top: 15px;
        > span {
          display: block;
          width: 230px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          border: 2px solid #e8e8e8;
          border-radius: 2px;
          font-size: 14px;
          color: #101010;
          cursor: pointer;
        }
      }
    }
    .Wsone-birthday,
    .Wsone-workTime {
      margin-bottom: 25px;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 520px;
        padding-right: 20px;
        margin-top: 15px;
        border: 2px solid #e8e8e8;
        box-sizing: border-box;
        cursor: pointer;
        > div:nth-child(1) {
          width: 500px;
          height: 42px;
          border-radius: 2px;
          cursor: pointer;
        }
        > img:nth-child(2) {
          width: 16px;
          height: 8px;
        }
      }
    }
    .Wsone-state {
      margin-bottom: 25px;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 50px;
        > span {
          display: block;
          width: 230px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          margin-bottom: 20px;
          border: 2px solid #e8e8e8;
          border-radius: 2px;
          font-size: 14px;
          color: #101010;
          cursor: pointer;
        }
      }
    }
  }
  .Wsone-nextSubmit {
    display: flex;
    justify-content: flex-end;
    width: 520px;
    margin: 0 auto;
    padding: 0 10px 50px;
    text-align: right;
    box-sizing: border-box;
    > span {
      display: block;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #2e74ff;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
//完善职场人弹窗2
.WsTwo {
  width: 600px;
  height: 614px;
  padding: 20px 30px 50px;
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .Wstwo-top {
    width: 520px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #2e74ff;
    > img:nth-child(1) {
      width: 40px;
      height: 40px;
      margin-right: 17px;
    }
  }
  .Wstwo-title {
    display: flex;
    flex-direction: column;
    width: 480px;
    margin: 50px auto 40px;
    font-size: 14px;
    color: #aaaaaa;
    > span:nth-child(1) {
      font-size: 22px;
      color: #101010;
      margin-bottom: 15px;
    }
  }
  .Wstwo-titles{
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 480px;
     margin: 50px auto 40px;
     >div:nth-child(1){
       display: flex;
       flex-direction: column;
       font-size: 14px;
       color: #aaaaaa;
        > span:nth-child(1) {
           font-size: 22px;
           color: #101010;
           margin-bottom: 15px;
         }
     }
     >div:nth-child(2){
      width: 80px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #d5e3ff;
      border-radius: 18px;
      font-size: 14px;
      color: #2E74FF;
      cursor: pointer;
      >img:nth-child(1){
        width: 18px;
        height: 12px;
        margin-right: 2px;
      }
     }
  }
  .Wstwo-companyInfo {
    width: 480px;
    margin: 0 auto;
    .company-name {
      display: flex;
      flex-direction: column;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > input:nth-child(2) {
        width: 480px;
        height: 42px;
        margin-top: 15px;
        padding-left: 10px;
        border: 2px solid #e8e8e8;
        border-radius: 2px;
        box-sizing: border-box;
        &:focus {
          border: 2px solid #e8e8e8;
          outline: none;
        }
      }
    }
    .company-time {
      width: 480px;
      margin: 30px auto;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        > div {
          display: flex;
          align-items: center;
          width: 230px;
          height: 42px;
          padding: 0 20px;
          border: 2px solid #e8e8e8;
          border-radius: 2px;
          box-sizing: border-box;
          > img:nth-child(2) {
            width: 16px;
            height: 8px;
          }
        }
      }
    }
    .company-position {
      display: flex;
      flex-direction: column;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > input:nth-child(2) {
        width: 480px;
        height: 42px;
        margin-top: 15px;
        padding-left: 10px;
        border: 2px solid #e8e8e8;
        border-radius: 2px;
        box-sizing: border-box;
        &:focus {
          border: 2px solid #e8e8e8;
          outline: none;
        }
      }
    }
  }
  .Wstwo-submit {
    display: flex;
    justify-content: flex-end;
    width: 480px;
    margin: 50px auto;
    > span {
      display: block;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-left: 30px;
      border: 2px solid #e8e8e8;
      border-radius: 2px;
      font-size: 14px;
      color: #101010;
      cursor: pointer;
    }
    > span:nth-child(2) {
      display: block;
      background: #2e74ff;
      border: 2px solid #2e74ff;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
//完善职场人弹窗3
.WsThree {
  width: 600px;
  height: 714px;
  padding: 20px 30px 50px;
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .WsThree-top {
    width: 520px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #2e74ff;
    > img:nth-child(1) {
      width: 40px;
      height: 40px;
      margin-right: 17px;
    }
  }
  .WsThree-title {
    display: flex;
    flex-direction: column;
    width: 480px;
    margin: 50px auto 40px;
    font-size: 14px;
    color: #aaaaaa;
    > span:nth-child(1) {
      font-size: 22px;
      color: #101010;
      margin-bottom: 15px;
    }
  }
  .WsThree-companyInfo {
    width: 480px;
    margin: 0 auto;
    .company-name {
      display: flex;
      flex-direction: column;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > input:nth-child(2) {
        width: 480px;
        height: 42px;
        margin-top: 15px;
        padding-left: 10px;
        border: 2px solid #e8e8e8;
        border-radius: 2px;
        box-sizing: border-box;
        &:focus {
          border: 2px solid #e8e8e8;
          outline: none;
        }
      }
    }
    .WsThree-education {
      width: 480px;
      margin: 30px auto;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        > div {
          width: 230px;
          height: 45px;
          border: 2px solid #e8e8e8;
          border-radius: 2px;
          box-sizing: border-box;
          /deep/.el-input--suffix .el-input__inner {
            padding-right: 4px;
          }
        }
      }
    }
    .company-time {
      width: 480px;
      margin: 30px auto;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        > div {
          display: flex;
          align-items: center;
          width: 230px;
          height: 42px;
          padding: 0 20px;
          border: 2px solid #e8e8e8;
          border-radius: 2px;
          box-sizing: border-box;
          > img:nth-child(2) {
            width: 16px;
            height: 8px;
          }
        }
      }
    }
    .company-position {
      display: flex;
      flex-direction: column;
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      > input:nth-child(2) {
        width: 480px;
        height: 42px;
        margin-top: 15px;
        padding-left: 10px;
        border: 2px solid #e8e8e8;
        border-radius: 2px;
        box-sizing: border-box;
        &:focus {
          border: 2px solid #e8e8e8;
          outline: none;
        }
      }
    }
  }

  .Wsthree-submit {
    display: flex;
    justify-content: flex-end;
    width: 480px;
    margin: 30px auto;
    > span {
      display: block;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-left: 30px;
      border: 2px solid #e8e8e8;
      border-radius: 2px;
      font-size: 14px;
      color: #101010;
      cursor: pointer;
    }
    > span:nth-child(2) {
      display: block;
      background: #2e74ff;
      border: 2px solid #2e74ff;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
.WsFour {
  width: 600px;
  height: 614px;
  padding: 20px 30px 50px;
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .WsFour-top {
    width: 520px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #2e74ff;
    > img:nth-child(1) {
      width: 40px;
      height: 40px;
      margin-right: 17px;
    }
  }
  .WsFour-title {
    display: flex;
    flex-direction: column;
    width: 480px;
    margin: 50px auto 40px;
    font-size: 14px;
    color: #aaaaaa;
    > span:nth-child(1) {
      font-size: 22px;
      color: #101010;
      margin-bottom: 15px;
    }
  }
  .WsFour-positionInfo {
    width: 480px;
    margin: 0 auto;
    .positionInfo-expect{
      > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      >div:nth-child(2){
        width: 480px;
        height: 42px;
        margin-top: 15px;
        border: 2px solid #E8E8E8;
        border-radius: 2px;
      }
    }
    .positionInfo-salary{
      margin: 30px 0;
       > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
       >div:nth-child(2){
        display: flex;
        justify-content: space-between;
        width: 480px;
        margin-top: 15px;
        >div{
          width: 230px;
          height: 42px;
          border: 2px solid #E8E8E8;
          border-radius: 2px;
        }
      }
    }
    .positionInfo-city{
       > span:nth-child(1) {
        font-size: 14px;
        color: #666666;
      }
      >div:nth-child(2){
        width: 480px;
        height: 42px;
        margin-top: 15px;
        border: 2px solid #E8E8E8;
        border-radius: 2px;
      }
    }
  }
  .WsFour-submit {
    display: flex;
    justify-content: flex-end;
    width: 480px;
    margin: 30px auto;
    > span {
      display: block;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-left: 30px;
      border: 2px solid #e8e8e8;
      border-radius: 2px;
      font-size: 14px;
      color: #101010;
      cursor: pointer;
    }
    > span:nth-child(2) {
      display: block;
      background: #2e74ff;
      border: 2px solid #2e74ff;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
.right {
  flex: 1;
  text-align: center;
  .navList {
    display: flex;
    justify-content: center;
    margin-top: 126px;
    > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 20px;
      font-size: 22px;
      color: #666666;
      cursor: pointer;
    }
  }
  .phoneLogin {
    width: 332px;
    margin: 60px auto 0;
    .phone {
      display: flex;
      align-items: center;
      width: 332px;
      height: 42px;
      padding-left: 20px;
      border: 2px solid #e8e8e8;
      border-radius: 4px;
      box-sizing: border-box;
      > div:nth-child(1) {
        width: 28px;
        margin-right: 10px;
        > img {
          width: 16px;
          height: 22px;
        }
      }
    }
    .codeNumber {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 332px;
      margin-top: 20px;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        width: 220px;
        height: 42px;
        padding-left: 20px;
        border: 2px solid #e8e8e8;
        border-radius: 4px;
        box-sizing: border-box;
        > div:nth-child(1) {
          width: 28px;
          margin-right: 10px;
          > img {
            width: 20px;
            height: 16px;
          }
        }
      }
      > div:nth-child(2) {
        width: 100px;
        height: 42px;
        line-height: 42px;
        font-size: 14px;
        text-align: center;
        background: #2e74ff;
        border-radius: 2px;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .loginBtm {
      width: 332px;
      height: 42px;
      line-height: 42px;
      margin-top: 30px;
      text-align: center;
      background: #eeeeee;
      border-radius: 2px;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
    }
    .SMDLAZC {
      display: flex;
      justify-content: space-around;
      margin-top: 40px;
      > span {
        display: block;
        width: 120px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #333333;
        text-align: center;
        border: 2px solid #e8e8e8;
        border-radius: 2px;
        cursor: pointer;
      }
    }
  }
}
.navAc {
  color: #101010 !important;
  font-weight: 700;
  &::after {
    content: "";
    display: block;
    width: 32px;
    height: 4px;
    margin-top: 10px;
    background: #2e74ff;
    border-radius: 2px;
  }
}
.loginBtmAc {
  background: #2e74ff !important;
  color: #ffffff !important;
}
.SMBtm {
  width: 200px;
  margin: 30px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #666666;
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 36px;
    border: 2px solid #e8e8e8;
    border-radius: 2px;
    font-size: 14px;
    color: #333333;
    cursor: pointer;
    > img:nth-child(1) {
      width: 14px;
      height: 18px;
      margin-right: 10px;
    }
  }
  > span:nth-child(2) {
    display: block;
    width: 68px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 2px solid #e8e8e8;
    border-radius: 2px;
    font-size: 14px;
    color: #2e74ff;
    cursor: pointer;
  }
}
.erweimaQh {
  text-align: left;
  display: flex;
  > img:nth-child(1) {
    width: 42px;
    height: 42px;
  }
  > div:nth-child(2) {
    width: 136px;
    height: 31px;
    line-height: 31px;
    text-align: center;
    background: url("@/assets/qipao.png") center center no-repeat;
    background-size: cover;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
  }
}
.Ac {
  background: #d5e3ff !important;
  border: 2px solid #2e74ff !important;
  border-radius: 2px;
}


</style>
