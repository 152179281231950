var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","min-height":"100vh","background":"#F7F8FA"}},[_c('div',{staticStyle:{"background-color":"#fff"}},[_c('Header',{attrs:{"switchCurrentIndex":'5'}})],1),_c('div',{staticClass:"lectureInfo"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('div',{staticClass:"info_top"},[_vm._m(1),_c('div',{staticClass:"info_top_right",on:{"click":_vm.myCourse}},[_vm._v("我的课程 >>")])]),_vm._m(2),_c('div',{staticClass:"info_bottom"},[_vm._m(3),_vm._l((3),function(item,index){return _c('div',{key:index,staticClass:"info_bottom_list"},[_c('img',{staticStyle:{"width":"20px","height":"16px","margin-right":"14px","margin-top":"4px"},attrs:{"src":require("@/assets/KnowledgeImg/video.png"),"alt":""}}),_vm._m(4,true)])})],2)])]),_vm._m(5),_c('div',{staticClass:"videoDiv"},_vm._l((8),function(item,index){return _c('div',{key:index,staticClass:"videoList",on:{"click":_vm.videoJump}},[_vm._m(6,true),_c('div',{staticClass:"videoList_title"},[_vm._v(" 测站坐标系统 ")]),_vm._m(7,true)])}),0),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{staticStyle:{"width":"860px","height":"380px"},attrs:{"src":"http://www.dixinbao.cn/uploads/20230808/66b8761c161ac8378608d35b47c99115.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_top_left"},[_c('img',{staticStyle:{"width":"36px","height":"36px","margin-right":"20px"},attrs:{"src":require("../../../assets/luntan/头像 拷贝.png"),"alt":""}}),_c('div',[_vm._v(" 张希 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_center"},[_c('div',{staticClass:"info_center_div"},[_c('div',[_vm._v("今日学习")]),_c('div',{staticClass:"info_center_div_time"},[_vm._v("15 "),_c('b',[_vm._v("分钟")])])]),_c('div',{staticClass:"info_center_div"},[_c('div',[_vm._v("坚持学习")]),_c('div',{staticClass:"info_center_div_time"},[_vm._v("10 "),_c('b',[_vm._v("小时")]),_vm._v(" 15 "),_c('b',[_vm._v("分钟")])])]),_c('div',{staticClass:"info_center_div"},[_c('div',[_vm._v("累计学分")]),_c('div',{staticClass:"info_center_div_time"},[_vm._v("15")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_bottom_title"},[_c('div',[_vm._v(" 最近学习 ")]),_c('img',{staticStyle:{"width":"8px","height":"12px"},attrs:{"src":require("../../../assets/KnowledgeImg/right.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_bottom_listInfo"},[_c('div',[_vm._v("VSS工程测量")]),_c('div',{staticClass:"info_bottom_listInfoText"},[_vm._v("讲师：陈慧 · 黄河水利职业技术学院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabDiv"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videoList_img"},[_c('img',{staticClass:"videoList_imgBg",attrs:{"src":"","alt":""}}),_c('img',{staticClass:"videoList_imgzanting",staticStyle:{"width":"40px","height":"40px"},attrs:{"src":require("@/assets/IndexImg/zanting.png"),"alt":""}}),_c('div',{staticClass:"videoList_imgTime"},[_vm._v("00:15:16")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videoList_info"},[_c('div',{},[_vm._v("讲师：党争")]),_c('div',{staticClass:"videoList_info_gun"}),_c('div',{},[_vm._v("黄河水利职业技术学院")])])
}]

export { render, staticRenderFns }