var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","min-height":"100vh","background":"linear-gradient(0deg, #E3F4FE 0%, #F0F9FF 100%)"}},[_c('div',{staticStyle:{"background-color":"#fff"}},[_c('Header',{attrs:{"switchCurrentIndex":'5'}})],1),_c('div',{staticClass:"video"},[_c('div',{staticClass:"videoShow"},[_c('div',{staticClass:"videoShow_left"},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player vjs-custom-skin",attrs:{"playsinline":true,"options":_vm.playerOptions}})],1),_c('div',{staticClass:"videoShow_right"},[_c('div',{staticClass:"videoShow_right_title"},[_vm._v("合集（共6节）")]),_vm._l((6),function(item,index){return _c('div',{key:index,staticClass:"videoShow_right_list"},[_c('div',{staticClass:"videoShow_right_listImg"}),_vm._m(0,true)])})],2)]),_vm._m(1)]),_vm._m(2),_c('div',{staticClass:"recommendVideo"},[_vm._m(3),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between","margin":"0 22px","margin-top":"18px"}},_vm._l((4),function(item,index){return _c('div',{key:index,staticClass:"videoList"},[_vm._m(4,true),_c('div',{staticClass:"videoList_title"},[_vm._v(" 测站坐标系统 ")]),_vm._m(5,true)])}),0)]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videoShow_right_listRight"},[_c('div',{staticStyle:{"width":"142px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v("CNSS工程测量")]),_c('div',{staticClass:"videoShow_right_listRightBtm"},[_vm._v("讲师：陈慧 · 黄河水利职业技术学院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videoBTM"},[_c('img',{staticStyle:{"width":"16px","height":"12px","margin-right":"12px"},attrs:{"src":require("@/assets/KnowledgeImg/look.png"),"alt":""}}),_c('div',[_vm._v(" 895次播放 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videoDescribe"},[_c('div',{staticClass:"videoDescribe_title"},[_vm._v(" CNSS工程测量（二） ")]),_c('div',{staticClass:"videoDescribe_lecturer"},[_vm._v(" 讲师：陈慧 · 黄河水利职业技术学院 ")]),_c('div',{staticClass:"videoDescribe_tab"},[_vm._v(" 土木 ")]),_c('div',{staticClass:"videoDescribe_text"},[_vm._v(" 简介：视频简介视频简介，视频简介视频简介视频简介，视频简介视 视频简介视频简介视频简介，视频简介频简介视频简介视频视频简介频简介视频简介视频视频简介视频简介，视频简介 视频简介视频简介，视频简介视 视频简介视频简介视频简介，视频简介频简介视频简介视频视频简介频简介视频简介视频 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recommendVideo_title"},[_c('div',[_vm._v("为你推荐")]),_c('div',{staticClass:"recommendVideo_titleMore"},[_c('div',[_vm._v("更多")]),_c('img',{staticStyle:{"width":"18px","height":"18px","margin-left":"13px"},attrs:{"src":require("@/assets/KnowledgeImg/moreIcon.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videoList_img"},[_c('img',{staticClass:"videoList_imgBg",attrs:{"src":"","alt":""}}),_c('img',{staticClass:"videoList_imgzanting",staticStyle:{"width":"40px","height":"40px"},attrs:{"src":require("@/assets/IndexImg/zanting.png"),"alt":""}}),_c('div',{staticClass:"videoList_imgTime"},[_vm._v("00:15:16")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videoList_info"},[_c('div',{},[_vm._v("讲师：党争")]),_c('div',{staticClass:"videoList_info_gun"}),_c('div',{},[_vm._v("黄河水利职业技术学院")])])
}]

export { render, staticRenderFns }