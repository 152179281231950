<template>
  <div>
    <div class="info">
          <div class="info_top">
            <div>
                <img src="@/assets/luntan/头像 拷贝.png" alt="" style="width: 46px;height: 46px;margin-right: 23px;">
                <span>张希</span>
            </div>

            <div>
              设置
            </div>
          </div>

          <div class="info_list">
              <div v-for="(item,index) in infoList" :key="index">

                <div style="width: 42px;
                height: 42px;
                background: #2E74FF;
                border-radius: 6px;
                margin-left: 22px;
                margin-right: 18px;
                "
                ></div>


                <div style="display: flex;flex-direction: column;">
                  <span>{{ item.companyName }}  
                    
                    <div
                    v-if="item.companyType"
                    style="
                    display: inline-block;
                    width: 52px;
                    height: 16px;
                    background: #ddd;
                    border-radius: 2px;
                    margin-left: 12px;
                    text-align: center;
                    ">
                      <span style="color: #2E74FF;">{{ item.companyType }}</span>
                    </div>
                  </span>
                  <span style="color: #666666;">{{ item.auth }}</span>
                </div>

                <i></i>
              </div>
          </div>


          <div class="info_btn">
            <span>创建或加入团队</span>
          </div>
        </div>
        <div class="qrInfo">
          <img src="@/assets/uploadPic.jpg" alt="" style="width: 62px;height: 62px;margin-right: 15px;margin-left: 22px;">
          <div>
            <div class="title">下载地信宝APP</div>
            <div class="text">
              找工作、找人才、找项目、找设备、 找合作、一个APP全搞定
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
    data(){
      return{

      }
    },
    props:{
      infoList:{
        type:Array,
        default:()=>{
          return []
        }
      }
    }
}
</script>

<style lang="less" scoped>
.info{
      width: 362px;
      height: 358px;
      background: #FFFFFF;
      border-radius: 4px;
      .info_top{
        margin: 0 auto;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 22px;
        div:nth-child(1){
          display: flex;
          align-items: center;
          span{
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #101010;
            line-height: 26px;
          }
        }
        div:nth-child(2){
          width: 60px;
          height: 30px;
          border-radius: 6px;
          border: 1px solid #AAAAAA;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .info_list{
        margin-top: 26px;
        >div{
          width: 362px;
          height: 82px;
          background: #F7F8FA;
          display: flex;
          align-items: center;
        }
      }

      .info_btn{
        width: 322px;
        height: 48px;
        border-radius: 6px;
        border: 2px solid #2E74FF;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 16px;
        color: #2E74FF;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
      }
    }
    .qrInfo{
      width: 362px;
      height: 94px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 16px;
      display: flex;
      align-items: center;

      .title{
        font-size: 14px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #101010;
      }
      .text{
        font-size: 12px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 6px;
        margin-right: 25px;
      }
    }
</style>