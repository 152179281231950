import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from 'element-ui';
Vue.prototype.$message = Message;
Vue.use(ElementUI);

import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import "./util/flexm";

import Hearder from './components/Header.vue'
Vue.component('Hearder',Hearder)
import Footer from './components/Footer.vue'
Vue.component('Footer',Footer)

//引入网络请求
import request from './network/index'
Vue.prototype.$http = request

//网站seo优化
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

//引入事件总线
import VueBus from 'vue-bus';
Vue.use(VueBus);

Vue.config.productionTip = false

//地图
// import VueAMap from "vue-amap"
// Vue.use(VueAMap)


// VueAMap.initAMapApiLoader({
//     key: "8e22b47d34de3b9f1cd3130212d875ad",
//     plugin: [
//         "AMap.Autocomplete", //输入提示插件
//         "AMap.PlaceSearch", //POI搜索插件
//         "AMap.Scale", //右下角缩略图插件 比例尺
//         "AMap.OverView", //地图鹰眼插件
//         "AMap.ToolBar", //地图工具条
//         "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
//         "AMap.Geocoder" // 逆地理编码,通过经纬度获取地址所在位置详细信息
//         // 根据需求选用
//     ],
//     uiVersion: "1.0", // 地图ui版本
//     v: '1.4.4' // amap版本
// })

// window._AMapSecurityConfig = {
//     securityJsCode: 'ccb6b6aaf1cb9f007d30315b88e3c299',
// }

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')