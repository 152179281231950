<template>
  <div style="height: 972px;background: linear-gradient(0deg, #FFFFFF 0%, rgba(188,229,255,0.4) 100%);">
    <div style="background:#2E74FF">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>


    <div class="Staging">
      <div class="Staging_left">
        <div class="RecentlyUsed">
          <div class="RecentlyUsed_title">
            <div class="gunzi"></div>
            最近使用
          </div> 

          <div class="RecentlyUsed_list">
            <div v-for="(item,index) in applicationList" :key="index">
              <img src="../../assets/StagingImg/clockIn.png" alt="" srcset="" style="width: 40px;height: 40px;margin-right: 12px;">
              <span>
                {{ item.text }}
              </span>
            </div>
            
          </div>
          
        </div>


        <div class="applicationList">

          <div class="RecentlyUsed_title">
            <div class="gunzi"></div>
            全部应用
          </div> 

          <div class="class_list">
            <div v-for="(item,i) in classList" :key="i" 
                @click="handClass(i)" 
                :class="i == index ? 'classStyle' : 'classStyles'"
            >
              <span>{{ item }}</span>
            </div>
          </div>
            

          <div class="RecentlyUsed_lists">
              <div v-for="(item,index) in list" :key="index">
                  <img src="../../assets/StagingImg/clockIn.png" alt="" srcset="" style="width: 40px;height: 40px;margin-right: 12px;">
                <span>
                  {{ item.text }}
                </span>
              </div>
          </div>
          


        </div>
      </div>


      <div class="Staging_right">
        <WorkbenchInfo :infoList="infoList"></WorkbenchInfo>
      </div>
    </div>


    <Footer></Footer>
  </div>
</template>
<script>
import WorkbenchInfo from '../../components/Workbench/WorkbenchInfo.vue'
export default {
  data(){
    return{
      applicationList:[{
        imgUrl:'../../assets/StagingImg/clockIn.png',
        text:'考勤打卡'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      }],
      classList:['人事','财务','项目','会议','日志'],
      index:0,
      list:[{
        imgUrl:'../../assets/StagingImg/clockIn.png',
        text:'考勤打卡'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      },{
        imgUrl:'../../assets/StagingImg/clockIn.png',
        text:'考勤打卡'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      }],

      infoList:[
        {
          companyName:'河南达观',
          auth:'未认证',
          companyType:'主企业',
        },
        {
          companyName:'地信宝',
          auth:'未认证',
          companyType:'',
        }
      ]
    }
  },
  components:{
    WorkbenchInfo
  },
  methods:{
    handClass(index){
      this.index = index
    }
  }
}
</script>
<style lang="less" scoped>
.Staging{
  width: 1200px;
  margin: auto;
  margin-top: 16px;
  display: flex;
  .Staging_left{
    width: 816px;
    margin-right: 22px;
    .RecentlyUsed{
      width: 816px;
      height: 138px;
      background: #FFFFFF;
      border-radius: 2px;
      overflow: hidden;
      .RecentlyUsed_title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 20px;
        .gunzi{
          width: 6px;
          height: 16px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
          margin-right: 16px;
        }
      }

      .RecentlyUsed_list{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        div{
          display: flex;
          align-items: center;
          img{
            width: 40px;
            height: 40px;
            margin-right: 12px;
          }
          span{
            font-weight: 400;
            font-size: 14px;
            color: #101010;
          }
        }
      }
    }
    .applicationList{
      width: 816px;
      min-height: 500px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 16px;

      .RecentlyUsed_title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
        margin-left: 20px;
        padding-top: 26px;
        .gunzi{
          width: 6px;
          height: 16px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
          margin-right: 16px;
        }
      }

      .class_list{
          margin-top: 29px;
          display: flex;
          .classStyle{
            width: 76px;
            height: 32px;
            background: #EAF1FF;
            border-radius: 6px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #2E74FF;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
          }

          .classStyles{
            width: 76px;
            height: 32px;
            background: #F7F8FA;
            border-radius: 6px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #101010;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
          }
      }

      .RecentlyUsed_lists{
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-left: -20px;
        div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 30px;
          img{
            width: 40px;
            height: 40px;
            margin-left: 58px;
          }
          span{
            font-weight: 400;
            font-size: 14px;
            color: #101010;
          }
        }
      }
    }
  }
  .Staging_right{
    width: 362px;
  }
}
</style>