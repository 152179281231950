<template>
  <div style="width: 100%;min-height: 100vh;background: linear-gradient(0deg, #E3F4FE 0%, #F0F9FF 100%);">
    <div style="background-color: #fff;">
      <Header :switchCurrentIndex="'5'"></Header>
    </div>
    <div class="video">
      <div class="videoShow">
        <div class="videoShow_left">
          <video-player  
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          ></video-player>
        </div>
        <div class="videoShow_right">
          <div class="videoShow_right_title">合集（共6节）</div>
          <div v-for="(item,index) in 6" :key="index" class="videoShow_right_list">
            <div class="videoShow_right_listImg"></div>
            <div class="videoShow_right_listRight"> 
              <div style="width: 142px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">CNSS工程测量</div>
              <div class="videoShow_right_listRightBtm">讲师：陈慧 · 黄河水利职业技术学院</div>
            </div>
          </div>
        </div>
      </div>
      <div class="videoBTM">
        <img src="@/assets/KnowledgeImg/look.png" alt="" style="width:16px;height:12px;margin-right:12px">
        <div>
          895次播放
        </div>
      </div>
    </div>
    <div class="videoDescribe">
      <div class="videoDescribe_title">
        CNSS工程测量（二）
      </div>
      <div class="videoDescribe_lecturer">
        讲师：陈慧 · 黄河水利职业技术学院
      </div>
      <div class="videoDescribe_tab">
        土木
      </div>
      <div class="videoDescribe_text">
        简介：视频简介视频简介，视频简介视频简介视频简介，视频简介视 视频简介视频简介视频简介，视频简介频简介视频简介视频视频简介频简介视频简介视频视频简介视频简介，视频简介
视频简介视频简介，视频简介视 视频简介视频简介视频简介，视频简介频简介视频简介视频视频简介频简介视频简介视频
      </div>
    </div>
    <div class="recommendVideo">
      <div class="recommendVideo_title">
        <div>为你推荐</div>
        <div class="recommendVideo_titleMore">
          <div>更多</div>
          <img src="@/assets/KnowledgeImg/moreIcon.png" alt="" style="width:18px;height:18px;margin-left:13px;">
        </div>
      </div>
      <div style="display: flex;align-items: center;justify-content: space-between;margin: 0 22px;margin-top:18px;">
        <div class="videoList" v-for="(item,index) in 4" :key="index" >
          <div class="videoList_img" >
            <img src="" alt="" class="videoList_imgBg">
            <img src="@/assets/IndexImg/zanting.png" alt="" style="width: 40px;height: 40px;" class="videoList_imgzanting">
            <div class="videoList_imgTime">00:15:16</div>
          </div>
          <div class="videoList_title">
            测站坐标系统
          </div>
          <div class="videoList_info">
            <div class="">讲师：党争</div>
            <div class="videoList_info_gun"></div>
            <div class="">黄河水利职业技术学院</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// 以下三行一定要引入
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/lang/zh-CN'
 
export default {
	// name: 'videoplayer',
	components: { // 必需引入
		videoPlayer,
    Header,
    Footer
  },
  props: [ // 接收父组件的数据
    'mp4Pic',
    'mp4Url'
  ],
	data () {
		return {
			fileAreaHeight: 675,
			fileType: 'mp4', // 资源的类型
		}
	},
	computed: { // 使用计算属性
	    playerOptions () {
	      const playerOptionsObj = {
	        playbackRates: [0.7, 1.0, 1.5, 2.0], //视频播放速度
	        autoplay: true, // 如果true，浏览器准备好时开始回放。
	        muted: false, // 默认情况下将会消除任何音频。
	        loop: false, // 导致视频一结束就重新开始。
	        // preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）。
	        language: 'zh-CN',
	        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）。
	        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
	        sources: [{
	          type: 'video/' + this.fileType,	// 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误。
	          src: 'http://res.dixinbao.cn/uploads/20240103/214ed4cae7588a3efd9ae75108c64693.mp4' // 视频url地址
	        }],
	        poster: this.mp4Pic, // 视频封面地址
	        // width: document.documentElement.clientWidth,
          // width: 1200,
	        height: 510,	// 设置高度，fluid需要设置成flase
	        notSupportedMessage: '此视频暂无法播放...', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
	        controlBar: {
	          timeDivider: true, // 当前时间和持续时间的分隔符
	          durationDisplay: true,  // 显示持续时间
	          remainingTimeDisplay: false,  // 是否显示剩余时间功能
	          fullscreenToggle: true  //全屏按钮
	        }
	      }
	      return playerOptionsObj
      }
    },
    watch: {
 
    }
  }
</script>

<style scoped lang="less">
  .video /*可不设置*/
  {
    margin: 48px 0;
  }
  .vjs-poster {
    background-color: #aaaaaa00;
  }

/*播放按钮设置成宽高一致，圆形，居中*/
.vjs-custom-skin > .video-js .vjs-big-play-button {
  outline: none;
  border: none;
  width: 66px;
  height: 66px !important;
  background-color: rgba(0,0,0,0) !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: '';
  width: 66px;
  height: 66px;
  /* background: url('../assets/icon_stop@2x.png') no-repeat; */
  background-size: 100% 100%;
}


/*control-bar布局时flex，通过order调整剩余时间的位置到进度条右边*/
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-remaining-time{
  order:3 !important;
}

/* 进度条下面的播放按钮 */
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-play-control {
  margin: 0;
  line-height: 20px;
  height: 94px;
  padding: 50px 0 24px 0;
}
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-play-control .vjs-icon-placeholder:before {
  position: absolute;
  font-size: 20px;
  top: 44px;
  left: 24px;
  width: 20px;
  height: 20px;
}

/** 时间组件 */
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-time-control {
  margin: 0;
  line-height: 20px;
  height: 94px;
  padding: 50px 0 24px 0;
  min-width: auto;
}
/* 时间-左 */
.video-js .vjs-current-time, .vjs-no-flex .vjs-current-time {
  min-width: 32px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  margin: 0 0 0 24px !important;
}
/* 下面控件的时间分割线 */
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-time-divider {
  min-width: 6px;
  margin: 0 8px !important;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.2);
  line-height: 20px;
}
/* 时间-右 */
.video-js .vjs-duration, .vjs-no-flex .vjs-duration {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
.video-js .vjs-control-bar {
  height: 94px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)
}
/*进度条单独放置一行*/
.vjs-custom-skin > .video-js .vjs-progress-control.vjs-control{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 68px;
  width: 100%;
  height: 2px;
  padding: 0 24px;
}
/* 进度条背景轨道 */
.video-js .vjs-slider{
  border-radius: 1em;
  background-color: rgba(255,255,255,0.2);
}
/* 加载进度条背景色 */
.video-js .vjs-load-progress {
  background: rgba(255,255,255,0.1);
}
/* 进度条进度 */
.vjs-custom-skin > .video-js .vjs-play-progress, .vjs-custom-skin > .video-js .vjs-volume-level{
  border-radius: 1px;
  background: #FFFFFF;
}
 
/*鼠标进入播放器后，播放按钮颜色会变*/
.video-js:hover .vjs-big-play-button, .vjs-custom-skin>.video-js .vjs-big-play-button:active, .vjs-custom-skin>.video-js .vjs-big-play-button:focus{
  background-color: rgba(0,0,0,0) !important;
}
 
/*control bar*/
.video-js .vjs-control-bar{
  background-color: rgba(0,0,0,0.2) !important;
}
 
/*点击按钮时不显示蓝色边框*/
.video-js .vjs-control-bar button{
  outline: none;
}
.vjs-volume-panel .vjs-control .vjs-volume-panel-horizontal {
  width: 0;
  display: none;
}
/** 隐藏倍速 */
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-playback-rate {
  display: none;
}
/** 音量按钮 */
.video-js .vjs-volume-panel {
  /* display: none; */
  position: absolute;
  right: 48px;
  bottom: 24px;
  width: 20px;
  height: 20px;
}
.vjs-icon-volume-high:before, .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  font-size: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: rgba(255,255,255,0.9);
}
.video-js .vjs-volume-bar {
  margin: 8px 16px 8px 0;
}
.video-js .vjs-volume-level {
  left: -21px;
}
/* 全屏组件 */
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-fullscreen-control {
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 20px;
  height: 20px;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .vjs-button > .vjs-icon-placeholder:before {
  text-align: right;
}
/* 全屏按钮图标 */
.vjs-icon-fullscreen-enter:before, .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: '';
  width: 20px;
  height: 20px;
  background: url('@/assets/KnowledgeImg/fullScreen.png') no-repeat;
  background-size: 100% 100%;
}
/* 全屏播放后隐藏自定义全屏图标 */
.vjs-icon-fullscreen-exit:before, .video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
  background: url('');
  line-height: 20px;
  margin-right: 10px;
}
.video{
  width: 1200px;
  margin: auto;
  margin-top: 20px;
  .videoShow{
    display: flex;
    
    .videoShow_left{
      width: 900px;
      height: 510px;
      background: #FFFFFF;
      border-radius: 6px 0px 0px 0px;
    }
    .videoShow_right{
      width: 300px;
      height: 510px;
      background: #FFFFFF;
      border-radius: 0px 6px 0px 0px;
      overflow-y: auto;
      overflow-x: hidden;
      .videoShow_right_title{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-left: 20px;
        margin-top: 18px;
        margin-bottom:16px;
      }
      .videoShow_right_list{
        width: 300px;
        height: 86px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        .videoShow_right_listImg{
          min-width: 102px;
          height: 62px;
          background: #f7f8fa;
          border-radius: 2px;
          margin-left: 20px;
        }
        .videoShow_right_listRight{
          height: 62px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 12px;
          margin-right: 24px;
        }
        .videoShow_right_listRightBtm{
          width: 142px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .videoBTM{
    width: 1180px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 0px 0px 6px 6px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
}
.videoDescribe{
  width: 1200px;
  height: 212px;
  background: #FFFFFF;
  border-radius: 6px;
  margin: 18px auto;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  overflow: hidden;
  .videoDescribe_title{
    font-size: 18px;
    font-weight: 500;
    color: #101010;
    margin-left: 22px;
    margin-top: 18px;
    margin-bottom: 6px;
  }
  .videoDescribe_lecturer{
    margin-left: 22px;
    margin-bottom:12px;

  }
  .videoDescribe_tab{
    margin-left: 22px;
    margin-bottom: 18px;
    width: 60px;
    height: 28px;
    background: rgba(46, 116, 255, 0.2);
    border-radius: 6px;
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    font-weight: 500;
    color: #2E74FF;
  }
  .videoDescribe_text{
    margin: 0 22px;
    line-height: 25px;
  }
}
.recommendVideo{
  width: 1200px;
  height: 346px;
  background: #FFFFFF;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 20px;
  font-family: PingFang SC;
  overflow: hidden;
  .recommendVideo_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 22px;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    margin-top: 18px;
    .recommendVideo_titleMore{
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
    }
  }
  .videoList{
    width: 274px;
    height: 260px;
    background: #FFFFFF;
    border-radius: 4px;
    .videoList_img{
      position: relative;
      width: 274px;
      height: 154px;
      background: #999;
      border-radius: 4px;
      .videoList_imgBg{
        
      }
      .videoList_imgzanting{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
      .videoList_imgTime{
        position: absolute;
        bottom: 9px;
        right: 12px;
        margin: auto;
        width: 60px;
        height: 22px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .videoList_title{
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #101010;
      margin-left: 12px;
      margin-top: 10px;
    }
    .videoList_info{
      display: flex;
      align-items: center;
      margin-top: 35px;
      margin-left: 12px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      .videoList_info_gun{
        width: 2px;
        height: 12px;
        background: #F6F6F6;
        border-radius: 1px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}
.videoShow_right::-webkit-scrollbar
{
    /*滚动条整体样式*/
    // width: 8px;     /*高宽分别对应横竖滚动条的尺寸*/  
    // height: 1px;
    width: 4px;
    // height: 100px;
    // background: #E8E8E8;
    border-radius: 2px;
}
.videoShow_right::-webkit-scrollbar-thumb
{
    /*滚动条里面小方块*/
    border-radius: 10px;  
    background: #E8E8E8;  
    // box-shadow: inset 0 0 5px #fff;
}
//滚动条底层颜色!
.videoShow_right::-webkit-scrollbar-track
{
    border-radius: 10px; 
     background: #fff;

/*滚动条里面轨道*/
    // box-shadow: inset 0 0 5px rgba( 0, 0, 0, .1);
}
</style>