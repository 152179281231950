<template>
  <div class="contain">
    <el-amap
      ref="map"
      vid="amapDemo"
      :center="center"
      :zoom="zoom"
      class="amap-demo"
    >
      <el-amap-marker :position="center" key="100"></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
export default {
  name: "AMapDemo",
  data() {
    return {
      zoom: 12,
      searchOption: {
        // 限制搜索城市的范围
        citylimit: false,
      },
    };
  },
  props: {
    center: {
      type: Array,
      default() {
        return [110.625351, 34.746303];
      },
    },
  },
  async mounted() {
    console.log(this.id, this.center, "addressid11");
    // this.getLocation();
  },
  methods: {
    // 获取定位
    getLocation() {
      const _this = this;
      ("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result);
            _this.autolocation = result.city;
          }
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.amap-demo {
  width: 100%;
  height: 300px;
  position: relative;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-box {
  height: 40px;
  // border-radius:16px;
  box-shadow: none;
  background: #ffff;
  border: 1px solid #e6e6e6;

  .search-box-wrapper {
    input {
      background: #fff;
      padding-left: 20px;
    }

    .search-btn {
      color: #2a67fe;
      width: 90px;
      height: 20px;
      box-sizing: border-box;
      border-left: 1px solid #d7d7d7;
    }
  }
}
</style>
